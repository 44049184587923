<template>
  <div class="q-mt-md">
    <c-upload-picture
      height="300px"
      :attachInfo="attachInfo"
      :editable="false">
    </c-upload-picture>
  </div>
</template>

<script>
export default {
  name: 'jobRiskHazardPicture',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        ramRiskHazardId: '',  // 유해위험요인 일련번호
        disabled: false,
      }),
    },
  },
  data() {
    return {
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'RISK_HAZARD',
        taskKey: '',
      },
      editable: false,
    };
  },
  computed: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      this.$set(this.attachInfo, 'taskKey', this.popupParam.ramRiskHazardId)
      // list setting
    },
  }
};
</script>
